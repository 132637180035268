svg4everybody = require("svg4everybody");
require("tweenmax");

var Packery = require("packery");
var imagesLoaded = require("imagesloaded");

svg4everybody();

(function ($) {
  require("./brands.js");
  require("./maps.js");
  require('./product-filter.js');

  $.fn.extend({
    showTooltip: function (label) {
      return this.each(function () {
        var $tooltip = $(this).find(".js-tooltip").addClass("is-visible");
        setTimeout(function () {
          $tooltip.removeClass("is-visible");
        }, 3000);
        if (label && label.length > 0) $tooltip.text(label);
      });
    },
  });

  $.fn.objectFit = function () {
    $(this).each(function () {
      var $img = $(this).find("img");
      $(this).css("background-image", "url(" + $img.attr("src") + ")");
      $img.remove();
    });
  };

  Drupal.behaviors.objectFitBehavior = {
    attach: function (context, settings) {
      $(".object-fit", context).once("objectFit").objectFit();
    },
  };

  Drupal.behaviors.instafeedBehavior = {
    attach: function (context, settings) {
      $(".js-insta-feed", context)
        .once("instafeed")
        .each(function (_, feed) {
          $.getJSON(
            "https://insta-kubrick.netlify.app/eyeczedelgem.json",
            function (data) {
              const posts = data.posts.slice(0, 6).map(
                (
                  post
                ) => `<a href="${post.link}" class="c-insta-post" target="_blank">
<img src="${post.picture.localFile}" alt="${post.caption}" loading="lazy" />
</a>`
              );

              const $content = $(".js-content", feed);
              $content.html(posts.join(""));
            }
          );
        });
    },
  };

  Drupal.behaviors.popupBehavior = {
    attach: function (context, settings) {
      $(".js-popup", context)
        .once("lightBox")
        .each(function () {
          var popup = this;
          if (window.sessionStorage.getItem("seen-popup") == "true") {
            return true;
          }
          var overlay = document.querySelector(".js-popup-overlay");

          popup.removeAttribute("hidden");
          overlay.removeAttribute("hidden");

          var closers = document.querySelectorAll(".js-close-popup");

          function closePopup() {
            popup.setAttribute("hidden", "true");
            overlay.setAttribute("hidden", "true");
            window.sessionStorage.setItem("seen-popup", "true");
          }

          Array.from(closers).forEach(function (closer) {
            closer.onclick = closePopup;
          });
        });
    },
  };

  Drupal.behaviors.lightBoxBehavior = {
    attach: function (context, settings) {
      $(".c-extra-images", context)
        .once("lightBox")
        .each(function () {
          new SimpleLightbox({ elements: ".c-extra-images a" });
        });
    },
  };

  Drupal.behaviors.fancyGridBehavior = {
    attach: function (context, settings) {
      $(".c-fancy-grid", context)
        .once("fancyGrid")
        .each(function () {
          var $grid = $(this);
          console.log("grid grid");
          var packery = new Packery($grid[0], {
            itemSelector: ".c-fancy-grid__item",
            gutter: ".c-fancy-grid__gutter",
            transitionDuration: 0,
          });
          imagesLoaded($grid, function () {
            $grid.addClass("is-loaded");
            packery.layout();
          });
        });
    },
  };

  Drupal.behaviors.sliderBehavior = {
    attach: function (context, settings) {
      $(".c-slider", context)
        .once("slider")
        .each(function () {
          var $slider = $(this);
          // generate page items
          var $pager = $('<div class="c-slider__pager c-pager"></div>');
          var $slides = $(this).find(".c-slider__item");
          $slides.each(function (index) {
            index++;
            $pager.append($('<button class="c-pager__item"></button>'));
          });

          $pager.appendTo($slider);
          var $pageItems = $slider.find(".c-pager__item");

          var currentIndex = 0;
          var slideTimeout = null;

          $slides.eq(0).addClass("is-active");
          $pageItems.eq(0).addClass("is-active");
          TweenMax.set($slides.filter(":not(:eq(0))"), { opacity: 0 });

          function slide() {
            if (currentIndex >= $slides.length) currentIndex = 0;

            var $oldSlide = $slides
              .filter(".is-active")
              .removeClass("is-active");
            var $newSlide = $slides.eq(currentIndex).addClass("is-active");

            if ($newSlide.index() == $oldSlide.index())
              return resetSlideTimeout();

            $pageItems
              .removeClass("is-active")
              .eq(currentIndex)
              .addClass("is-active");

            var tl = new TimelineMax();

            tl.set($newSlide, { opacity: 1 });
            tl.to($oldSlide.find(".c-slider__baseline__inner"), 1, {
              y: "-120%",
              transformOrigin: "center",
              ease: Strong.easeInOut,
            });
            tl.fromTo(
              $newSlide.find(".c-slider__baseline__inner"),
              0.7,
              { y: "200%", transformOrigin: "center", ease: Strong.easeInOut },
              { y: "0%" },
              0.2
            );

            tl.to(
              $oldSlide.find(".c-slider__image"),
              1,
              { scale: 1.2, ease: Strong.easeOut, opacity: 0 },
              0
            );
            tl.fromTo(
              $newSlide.find(".c-slider__image"),
              1,
              { scale: 1.2, ease: Strong.easeOut, opacity: 0 },
              { scale: 1, opacity: 1 },
              0
            );

            resetSlideTimeout();
          }

          function nextSlide() {
            currentIndex++;
            slide();
          }

          function resetSlideTimeout() {
            if (slideTimeout) clearTimeout(slideTimeout);
            slideTimeout = setTimeout(nextSlide, 5000);
          }

          resetSlideTimeout();

          $pageItems.click(function () {
            currentIndex = $(this).index();
            slide();
          });
        });
    },
  };

  Drupal.behaviors.newsLetterBehavior = {
    attach: function (context, settings) {
      $(".js-newsletter", context)
        .once("newsLetter")
        .each(function () {
          var $newsletterWrapper = $(this);
          var $form = $(this).find("form");
          //callback handler for form submit
          $form.submit(function (e) {
            if ($form.hasClass("is-submitted")) return false;
            $form.addClass("is-submitted");
            console.log("submit");
            var postData = $(this).serializeArray();
            var formURL = $(this).attr("action");

            // $form.hide(0);
            $.ajax({
              url: formURL,
              type: "POST",
              data: postData,
              success: function (data, textStatus, jqXHR) {
                console.log(data);
                $newsletterWrapper.replaceWith(
                  $(data).find(".js-newsletter").first()
                );
                var $message = $(data).find(".messages").first();

                var $form = $(".js-newsletter form");

                if ($message.hasClass("messages--error")) {
                  $form.addClass("is-error");
                } else {
                  $form.empty();
                }

                $form.prepend($message);
                Drupal.attachBehaviors();
              },
              error: function (jqXHR, textStatus, errorThrown) {
                //if fails
              },
            });
            e.preventDefault(); //STOP default action
            e.stopPropagation(); //STOP barba too
            // e.unbind(); //unbind. to stop multiple form submit.
          });
        });
    },
  };

  Drupal.behaviors.flipBehavior = {
    attach: function (context, settings) {
      $(".js-flip-parent", context)
        .once("flip")
        .each(function () {
          $(this)
            .find(".js-flip")
            .each(function (index) {
              console.log(index);
              if (index % 2 == 0) {
                return true;
              }
              var $leftCols = $(this).find(".l-col--left");
              var $rightCols = $(this).find(".l-col--right");

              $leftCols.removeClass("l-col--left").addClass("l-col--right");
              $rightCols.removeClass("l-col--right").addClass("l-col--left");
            });
        });
    },
  };

  Drupal.behaviors.productCategoryMenuBehavior = {
    attach: function (context, settings) {
      $(".c-product-category-menu", context)
        .once("productCategoryMenu")
        .each(function () {
          var $categoryWrapper = $(".js-product-category-wrapper");
          if ($categoryWrapper.length > 0) {
            var tid = $categoryWrapper.attr("data-tid");
            $(".c-product-category-menu__item a").removeClass("is-active");
            $(
              ".c-product-category-menu__item a[data-tid=" + tid + "]"
            ).addClass("is-active");
          }
        });
    },
  };


  Drupal.behaviors.menuToggleBehavior = {
    attach: function (context, settings) {
      $(".c-menu-toggle")
        .once("menuToggle")
        .each(function () {
          // alert($('.c-menu-toggle').length);
          var $menuToggle = $(this);
          var $offCanvas = $(".l-offcanvas");
          var $overlay = $(".c-page-overlay");
          var $menuCloser = $(".c-menu-closer");

          function closeMenu() {
            $offCanvas.removeClass("is-open");
            $overlay.removeClass("is-visible");
          }

          $menuCloser.click(function () {
            closeMenu();
          });
          $overlay.click(function () {
            closeMenu();
          });

          $menuToggle.click(function () {
            $offCanvas.toggleClass("is-open");
            $overlay.toggleClass("is-visible");
            // alert('is open');
          });
        });
    },
  };
})(jQuery);
