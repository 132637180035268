require("tweenmax");

(function ($) {
  Drupal.behaviors.productTeaserBehavior = {
    attach: function (context, settings) {
      $(".l-product-grid", context)
        .once("productTeaser")
        .each(function () {
          var currentBrand = null;
          var currentTarget = null;
          var currentSubCat = null;
          var brands = [];
          var $productGrid = $(this);
          var $products = $(this).find(".c-product-teaser");

          var $brandFilter = $(
            '<div class="c-brand-filter"><button class="c-brand-filter__item is-active js-brand-filter" data-brand="all">Alle merken</button></div>'
          );

          $brandFilter.insertBefore($productGrid);

          var $noResults = $(
            "<div class='c-no-results'>Geen producten gevonden voor deze criteria. <button>Toon alle producten.</button></div>"
          );

          $noResults.on("click", "button", function () {
            currentBrand = null;
            currentTarget = null;
            currentSubCat = null;

            $brandFilter
              .find("button")
              .removeClass("is-active")
              .first()
              .addClass("is-active");

            $targetFilter.find("button").removeClass("is-active");
            $subCategoryFilter.find("button").removeClass("is-active");
            filter();
          });

          $noResults.insertBefore($productGrid);
          $noResults.hide(0);

          $products.each(function () {
            var brand = $(this).attr("data-brand");
            if (brand && brands.indexOf(brand) == -1) {
              brands.push(brand);
            }
          });

          var $targetFilter = $(".view-target");
          var $subCategoryFilter = $(".js-sub-category-filter");

          $subCategoryFilter.on("click", "button", filterProductsBySubCategory);

          function filterProductsBySubCategory() {
            if ($(this).hasClass("is-active")) {
              $(this).removeClass("is-active");
              currentSubCat = null;
              return filter();
            }

            $(this)
              .addClass("is-active")
              .closest(".views-row")
              .siblings()
              .find("button")
              .removeClass("is-active");
            currentSubCat = $(this).attr("data-tid");
            filter();
          }

          function filterProductsByBrand() {
            $(this).addClass("is-active").siblings().removeClass("is-active");
            currentBrand = $(this).attr("data-brand");
            filter();
          }

          $targetFilter.on(
            "click",
            ".js-target-filter",
            filterProductsByTarget
          );

          $brandFilter.on("click", ".js-brand-filter", filterProductsByBrand);

          function filterProductsByTarget() {
            if ($(this).hasClass("is-active")) {
              $(this).removeClass("is-active");
              currentTarget = null;
              return filter();
            }

            $(this)
              .addClass("is-active")
              .closest(".views-row")
              .siblings()
              .find("button")
              .removeClass("is-active");
            currentTarget = $(this).attr("data-target");
            filter();
          }

          function filter() {
            var $productsResults = $products;

            if (currentBrand && currentBrand != "all") {
              $productsResults = $products.filter(
                '[data-brand="' + currentBrand + '"]'
              );
            }
            if (currentTarget && currentTarget != "all") {
              $productsResults = $products.filter(function (_, el) {
                return el.dataset.target.split(",").includes(currentTarget);
              });
            }
            if (currentSubCat && currentSubCat != "all") {
              $productsResults = $products.filter(function (_, el) {
                return el.dataset.category.split(",").includes(currentSubCat);
              });
            }

            var $productsInvertedResults = $products.not($productsResults);
            TweenMax.set(
              $productsInvertedResults.closest(".l-product-grid__item"),
              { display: "none" }
            );
            TweenMax.set($productsResults.closest(".l-product-grid__item"), {
              clearProps: "all",
              display: "block",
            });

            if ($productsResults.length) {
              $noResults.hide(0);
            } else {
              $noResults.show(0);
            }
          }

          for (var i = 0; i < brands.length; i++) {
            var $brandButton = $(
              '<button class="c-brand-filter__item js-brand-filter" data-brand="' +
                brands[i] +
                '">' +
                brands[i] +
                "</button>"
            );
            $brandButton.appendTo($brandFilter);
          }
        });
    },
  };
})(jQuery);
