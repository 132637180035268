require('tweenmax');

(function ($) {
  Drupal.behaviors.brandsBehavior = {
    attach: function(context, settings){
      $('.c-brands', context).once('clients').each(function(){
        var slidesAtOnce = 5;
        var currentIndex = slidesAtOnce;
        var currentItemIndex = 0;
        var $clients = $(this).find('.c-brand__inner');
        var imageUrls = [];

        $clients.each(function() {
          var imageURL = $(this).find('img').attr('src');

          if(!imageURL) return false;
          if(imageURL.indexOf("?itok") > -1) {
            imageURL = imageURL.split('?itok')[0];
          }
          imageUrls.push(imageURL);
        });


        function shuffle(a) {
          var j, x, i;
          for (i = a.length; i; i--) {
            j = Math.floor(Math.random() * i);
            x = a[i - 1];
            a[i - 1] = a[j];
            a[j] = x;
          }
        }

        shuffle(imageUrls);
        $clients.empty();
        $clients.closest('.views-row').filter(':gt(' + (slidesAtOnce - 1) + ')').remove();

        $clients.each(function(index){
          TweenMax.set($(this), {backgroundImage: 'url('+imageUrls[index]+')'});
        });

        function change() {
          if(currentIndex >= imageUrls.length) currentIndex = 0;
          if(currentItemIndex >= slidesAtOnce) currentItemIndex = 0;
          var $item = $clients.eq(currentItemIndex);
          var tl = new TimelineMax();
          tl.to($item, 1, {opacity: 0});
          var imageURL = imageUrls[currentIndex];
          tl.set($item, {backgroundImage: 'url("'+imageURL+'")'});
          tl.to($item, 1, {opacity: 1});
          currentItemIndex++;
          currentIndex++;
        }

        if($clients.length < slidesAtOnce) return false;
        setInterval(change, 2000);
      });
    }
  };
})(jQuery);