(function ($) {
  Drupal.behaviors.googleMapBehavior = {
    attach: function (context, settings) {
      $(".js-map", context)
        .once("googleMap")
        .each(function () {
          var $mapContainer = $(this);

          function initMap(mapContainer) {
            var bound = new google.maps.LatLngBounds();
            var geoLocation = mapContainer.getAttribute("data-geolocation");
            geoLocation = geoLocation.split(",");
            geoLocation = { lat: geoLocation[0], lng: geoLocation[1] };
            var locations = [geoLocation];

            makeMap();

            function makeMap() {
              var gmarkers = [];
              var map = null;
              var infowindow = null;

              var styles = [
                {
                  featureType: "administrative",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
                {
                  featureType: "landscape",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
                {
                  featureType: "poi",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
                {
                  featureType: "road",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
                {
                  featureType: "transit",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
                {
                  featureType: "water",
                  elementType: "all",
                  stylers: [{ saturation: -99 }],
                },
              ];
              for (var i = 0; i < locations.length; i++) {
                var latLang = locations[i];
                bound.extend(new google.maps.LatLng(latLang.lat, latLang.lng));
              }

              var customZoom =
                mapContainer.getAttribute("data-custom-zoom") * 1;
              var zoomFactor = 15;
              if (customZoom) {
                zoomFactor = customZoom;
              }

              var mapOptions = {
                center: bound.getCenter(),
                zoom: zoomFactor,
                panControl: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                scrollwheel: false,
                draggable: false,
                styles: styles,
              };

              map = new google.maps.Map(mapContainer, mapOptions);

              function setMarkers(locations) {
                var markerImage = {
                  url: "/sites/all/themes/eyec_theme/gfx/marker.png",
                };
                for (var i = 0; i < locations.length; i++) {
                  var latLong = locations[i];
                  var myLatLng = new google.maps.LatLng(
                    latLong.lat,
                    latLong.lng
                  );
                  var marker = new google.maps.Marker({
                    position: myLatLng,
                    animation: google.maps.Animation.DROP,
                    map: map,
                    optimized: false,
                    icon: markerImage,
                  });
                  // Open google maps at coordinates on marker click
                  gmarkers.push(marker);

                  google.maps.event.addListener(marker, "click", function () {
                    window.open(
                      "https://maps.google.com/maps?daddr=" +
                        latLong.lat +
                        "," +
                        latLong.lng
                    );
                  });
                }
              }
              setMarkers(locations);
            }
          }

          $.getScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAupwHPK9I1CCzJKcHqAS-UTKzcfuIzZrE",
            function () {
              initMap($mapContainer[0]);
            }
          ); // jQuery shortcut
        });
    },
  };
})(jQuery);
